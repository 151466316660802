/* eslint-disable no-unused-expressions */
import { required, minLength, maxLength, helpers, email } from 'vuelidate/lib/validators';

const phoneCheck = helpers.regex('phoneCheck', /^(\d{3})(\d{3})(\d{2})(\d{2})$/);

const validations = {
	validations: {
		customer: {
			identityNo: { required, minLength: minLength(11) },
			email: { email },
			firstName: { required, minLength: minLength(2), maxLength: maxLength(50) },
			lastName: { required, minLength: minLength(2), maxLength: maxLength(50) },
			phone: { required, phoneCheck },
			cityId: { required },
			countyId: { required },
			address: { required, minLength: minLength(10), maxLength: maxLength(255) },
		},
	},
	methods: {
		// validation methods for this page
		identityNoErrors() {
			const errors = [];
			const { $dirty, required, minLength, $params } = this.$v.customer.identityNo;
			if (!$dirty) return errors;
			!required && errors.push('TC kimlik numarası girmeniz gerekmektedir.');
			!minLength && errors.push(`TC kimlik numarası ${$params.minLength.min} karakter olmalıdır.`);
			return errors;
		},
		emailErrors() {
			const errors = [];
			const { $dirty, email } = this.$v.customer.email;
			if (!$dirty) return errors;
			!email && errors.push('Lütfen geçerli bir e-mail adresi giriniz.');
			return errors;
		},
		firstNameErrors() {
			const errors = [];
			const { $dirty, required, minLength, maxLength, $params } = this.$v.customer.firstName;
			if (!$dirty) return errors;
			!required && errors.push('Lütfen adınızı yazın.');
			!minLength && errors.push(`Adınız min${$params.minLength.min} karakter olmalıdır.`);
			!maxLength && errors.push(`Adınız maks ${$params.maxLength.max} karakter olmalıdır.`);
			return errors;
		},
		lastNameErrors() {
			const errors = [];
			const { $dirty, required, minLength, maxLength, $params } = this.$v.customer.lastName;
			if (!$dirty) return errors;
			!required && errors.push('Lütfen soyadınızı yazın.');
			!minLength && errors.push(`Soyadınız min${$params.minLength.min} karakter olmalıdır.`);
			!maxLength && errors.push(`Soyadınız maks ${$params.maxLength.max} karakter olmalıdır.`);
			return errors;
		},
		phoneErrors() {
			const errors = [];
			const { $dirty, required, phoneCheck } = this.$v.customer.phone;

			if (!$dirty) return errors;
			!required && errors.push('Lütfen telefon numaranızı yazın');
			!phoneCheck && errors.push(`Lütfen geçerli bir telefon numarası yazın.`);
			return errors;
		},
		cityErrors() {
			const errors = [];
			const { $dirty, required } = this.$v.customer.cityId;

			if (!$dirty) return errors;
			!required && errors.push('Lütfen fatura için şehir seçin.');
			return errors;
		},
		countyErrors() {
			const errors = [];
			const { $dirty, required } = this.$v.customer.countyId;

			if (!$dirty) return errors;
			!required && errors.push('Lütfen fatura için ilçe seçin.');
			return errors;
		},
		adressErrors() {
			const errors = [];
			const { $dirty, required, minLength, maxLength, $params } = this.$v.customer.address;
			if (!$dirty) return errors;
			!required && errors.push('Lütfen fatura için açık adres yazın.');
			!minLength && errors.push(`Adres alanı min ${$params.minLength.min} karakter olmalıdır.`);
			!maxLength && errors.push(`Adres alanı maks ${$params.maxLength.max} karakter olmalıdır.`);
			return errors;
		},
	},
};

export default validations;
