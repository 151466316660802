<template>
	<div class="to-option--customer-info">
		<sms-validation
			ref="smsValidationRef"
			:form-data.sync="smsValidationForm"
			@valid-code="validateAndPayment"
			@resend-validation-code="resendValidationCode"
		></sms-validation>
		<v-card light color="transparent" flat>
			<h3 class="py-5 template-color--text text--darken-2">Kişisel Bilgiler</h3>
			<v-card-text class="pa-0">
				<v-row>
					<v-col cols="12" md="6">
						<v-text-field
							hide-details="auto"
							dense
							solo
							flat
							v-model="customer.identityNo"
							placeholder="TC Kimlik Numarası *"
							required
							maxlength="11"
							:error-messages="identityNoErrors()"
							@blur="$v.customer.identityNo.$touch()"
						/>
					</v-col>
					<v-col cols="12" md="6">
						<v-text-field
							hide-details="auto"
							dense
							solo
							flat
							v-model="customer.email"
							placeholder="E-posta"
							type="email"
							:error-messages="emailErrors()"
						/>
					</v-col>
					<v-col cols="12" md="4">
						<v-text-field
							hide-details="auto"
							dense
							solo
							flat
							v-model="customer.firstName"
							placeholder="Adınız *"
							required
							:error-messages="firstNameErrors()"
						/>
					</v-col>
					<v-col cols="12" md="4">
						<v-text-field
							hide-details="auto"
							dense
							solo
							flat
							v-model="customer.lastName"
							placeholder="Soyadınız *"
							required
							:error-messages="lastNameErrors()"
						/>
					</v-col>
					<v-col cols="12" md="4">
						<v-text-field-simplemask
							v-model="customer.phone"
							v-bind:properties="{
								inputmode: 'numeric',
								prefix: '+90',
								placeholder: '(555)-555-55-55 *',
								dense: true,
								solo: true,
								errorMessages: phoneErrors(),
								hideDetails: 'auto',
								flat: true,
							}"
							v-bind:options="{
								inputMask: '(###)-###-##-##',
								outputMask: '###########',
								empty: null,
								applyAfter: false,
								alphanumeric: false,
								lowerCase: false,
							}"
						/>
					</v-col>
					<v-col cols="12" md="6">
						<v-select
							hide-details="auto"
							dense
							solo
							flat
							v-model="customer.cityId"
							:items="cities"
							item-text="citName"
							item-value="citId"
							placeholder="İl *"
							required
							:error-messages="cityErrors()"
							@change="cityChanged"
						/>
					</v-col>
					<v-col cols="12" md="6">
						<v-select
							hide-details="auto"
							dense
							solo
							flat
							v-model="customer.countyId"
							:items="counties"
							item-text="couName"
							item-value="couId"
							placeholder="İlçe *"
							required
							:error-messages="countyErrors()"
						/>
					</v-col>
					<v-col cols="12" md="12">
						<v-textarea
							height="70"
							no-resize
							hide-details="auto"
							dense
							solo
							flat
							v-model="customer.address"
							placeholder="Adres *"
							required
							:error-messages="adressErrors()"
						/>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import ApiService from '@/common/api.service';
import { IS_PROD } from '@/common/globals';
import EventBus from '@/event-bus';
import {
	PAYMENT_RESERVATION_CAR,
	RESEND_RESERVATION_VALIDATION_CODE,
	RESERVE_CAR,
	VALID_SMS_CODE,
} from '@/store/modules/listing.module';
import { validationMixin } from 'vuelidate';
import goTo from 'vuetify/lib/services/goto';
import { mapActions } from 'vuex';
import SmsValidation from '../components/sms-validation/Index.vue';
import validations from './_validations';

export default {
	components: {
		SmsValidation,
	},
	name: 'CustomerInfo',
	mixins: [validationMixin, validations],
	data() {
		return {
			cookieName: 'reserveCookie',
			customer: {
				// identityNo: null,
				// email: null,
				// firstName: null,
				// lastName: null,
				// phone: null,
				// cityId: null,
				// countyId: null,
				// address: null,
			},
			cities: [],
			counties: [],
			smsValidationForm: {
				dialog: false,
				smsCode: null,
				timeOut: null,
			},
			posConfigs: {},
			psDialog: false,
		};
	},
	computed: {
		carId() {
			return this.$route.params.id;
		},
		showPsDialog() {
			return this.psDialog;
		},
		reservationCookie() {
			return this.$cookies.get(this.cookieName);
		},
	},
	mounted() {
		this.getCities();
		EventBus.$on('reserve-car', this.reserve);
	},
	beforeDestroy() {
		EventBus.$off('reserve-car');
	},
	methods: {
		...mapActions({
			reserveCar: RESERVE_CAR,
			paymentReservation: PAYMENT_RESERVATION_CAR,
			validSmsCode: VALID_SMS_CODE,
			resendCode: RESEND_RESERVATION_VALIDATION_CODE,
		}),
		checkValidation(approve) {
			this.$v.customer.$touch();
			approve.$touch();
			if (this.$v.customer.$invalid || approve.$invalid) return false;

			return true;
		},
		reserve(approve) {
			if (!this.checkValidation(approve)) {
				goTo(0);
				return;
			}

			const payload = { key: this.carId, approve: approve.$model, ...this.customer, phone: `90${this.customer.phone}` };
			this.reserveCar(payload).then((response) => {
				this.smsValidationForm.timeOut = response.smsTimeOut;
				this.smsValidationForm.dialog = true;

				this.$cookies.set(this.cookieName, { reservationKey: response.reservationKey }, 0);
			});
		},
		postPayment(ps) {
			const posConfigs = {
				mode: ps.mode,
				secure3dsecuritylevel: ps.securityLevel,
				apiversion: ps.version,
				terminalprovuserid: ps.termPUId,
				terminaluserid: ps.termUId,
				terminalid: ps.termId,
				terminalmerchantid: ps.termMarchId,
				orderid: ps.orderId,
				customeremailaddress: ps.email,
				customeripaddress: ps.ipAddress,
				txntype: ps.type,
				txnamount: ps.amount,
				txncurrencycode: ps.curCode,
				companyname: ps.company,
				txninstallmentcount: ps.installmentCount,
				successurl: ps.successUrl,
				errorurl: ps.errorUrl,
				secure3dhash: ps.hashData,
				lang: ps.lang,
				txntimestamp: ps.timestamp,
				refreshtime: ps.refreshTime,
			};

			const form = document.createElement('form');
			form.setAttribute('method', 'post');

			// eslint-disable-next-line no-empty
			if (IS_PROD) {
			}
			form.setAttribute('action', 'https://sanalposprovtest.garantibbva.com.tr/servlet/gt3dengine');

			Object.keys(posConfigs).forEach((key) => {
				const input = document.createElement('input');
				input.setAttribute('type', 'hidden');
				input.setAttribute('name', key);
				input.setAttribute('value', posConfigs[key]);
				form.appendChild(input);
			});

			document.body.appendChild(form);
			form.submit();
		},
		validateAndPayment() {
			const payload = {
				reservationKey: this.reservationCookie.reservationKey,
				smsCode: this.smsValidationForm.smsCode,
			};

			this.validSmsCode(payload).then((ps) => {
				this.postPayment(ps);
				this.smsValidationForm.dialog = false;
				this.smsCode = null;
			});
		},
		resendValidationCode() {
			this.resendCode({ key: this.reservationCookie.reservationKey }).then((timeOut) => {
				this.smsValidationForm.timeOut = timeOut;
				this.smsValidationForm.dialog = true;
				this.$refs.smsValidationRef.startCountDown();
			});
		},
		getCities() {
			ApiService.post('general/getcitylist').then((response) => {
				this.cities = response;
			});
		},
		cityChanged() {
			ApiService.post('general/getcountylistbycityid', { id: this.customer.cityId }).then((response) => {
				this.counties = response;
			});
		},
	},
};
</script>

<style lang="scss">
.to-option {
	.error {
		&--text {
			color: var(--v-error-base) !important;
		}
	}
	&--customer-info {
		margin-bottom: 50px;
		.v-input {
			&__slot {
				border: 1px solid rgb(238, 238, 238) !important;
			}
		}
	}
}
</style>
