<template>
	<v-dialog persistent max-width="350" v-model="formData.dialog" light>
		<v-card flat>
			<v-card-title>
				<h4>Sms Doğrulama</h4>
			</v-card-title>

			<v-card-text v-if="secondsLeft > 0" key="validation-code-sent">
				<div class="text-subtitle-1">Lütfen cep telefonunuza gönderilen 6 haneli sms doğrulama kodunu girin.</div>
				<v-row>
					<v-col>
						<v-otp-input dense ref="otp" solo v-model="formData.smsCode" @finish="validCode"></v-otp-input>
					</v-col>
				</v-row>
				<div class="text-right">{{ secondsLeft }}</div>
			</v-card-text>
			<v-card-text v-else key="validation-code-expired">
				<div class="d-flex justify-end">
					<div class="text-subtitle-1">
						Doğrulama kodunuzun süresi dolmuştur.
						<a class="font-weight-bold template-color--text" @click="resendValidation">Yeni kod gönder</a>
					</div>
				</div>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="template-color" class="text-capitalize" text outlined dark @click="close">Kapat</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'SmsValidation',
	props: {
		formData: {
			type: Object,
		},
	},
	data() {
		return {
			endTime: null,
			secondsLeft: 0,
			intervalId: null,
		};
	},
	mounted() {
		this.$nextTick(() => {
			this.$refs.otp?.focus();
		});
	},
	created() {
		this.startCountDown();
	},
	methods: {
		startCountDown() {
			this.endTime = this.$moment().add(this.formData.timeOut, 'minutes');
			let remainingTime = this.endTime.diff(this.$moment());
			this.secondsLeft = Math.round(remainingTime / 1000);
			this.intervalId = setInterval(() => {
				remainingTime = this.endTime?.diff(this.$moment());
				this.secondsLeft = Math.round(remainingTime / 1000);
				if (this.secondsLeft <= 0) {
					clearInterval(this.intervalId);
					this.secondsLeft = 0;
				}
			}, 1000);
		},
		validCode() {
			// this.$emit('update:formData', this.formData);
			this.$emit('valid-code');
		},
		resendValidation() {
			this.$emit('resend-validation-code');
		},
		close() {
			this.endTime = null;
			this.secondsLeft = 0;
			this.intervalId = null;
			this.formData.dialog = false;
		},
	},
	watch: {
		'formData.dialog': {
			handler(show) {
				if (show) {
					if (this.secondsLeft > 0) {
						this.$nextTick(() => {
							this.$refs.otp?.focus();
						});
					}
					this.startCountDown();
				}
			},
		},
	},
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
